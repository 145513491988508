(function($) {

	// scripts executed when this line is read

	$(document).on('ready', function() {

		// scripts executed when document is ready

	});

	$(window).on('load', function() {

		// scripts executed when window is completely loaded

	});

})(jQuery);
